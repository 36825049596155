<template>
  <div class="home-teacher" >
    <div v-if="info.user">
<!--      <div class="cont mt10" >-->
<!--        <p class="tit">您好！ {{ info.user.nickname }}，欢迎登录{{ info.user.site.name }}！</p>-->
<!--        <div class="data-info">-->
<!--          <div class="data-info-r-t">-->
<!--            <div class="data-info-exam app-bj-color border-left-red">-->
<!--              <img src="../../assets/images/new_icon/group_icon.png" width="48" height="48" />-->
<!--              <div class="data-info-exam-value">-->
<!--                <div class="data-info-exam-data">-->
<!--                  <p class="text-color">{{ info.userCount }}</p>-->
<!--                  <p>累计用户数</p>-->

<!--                </div>-->
<!--                <div class="app-fgx"></div>-->
<!--                <div class="data-info-exam-data">-->
<!--                  <p class="text-color">{{ info.userCount7days }}</p>-->
<!--                  <p>七日活跃人数</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="data-info-exam ml20 app-bj-color border-left-yellow">-->
<!--              <img src="../../assets/images/new_icon/manage_icon.png" width="48" height="48" />-->
<!--              <div class="data-info-exam-value">-->
<!--                <div class="data-info-exam-data">-->
<!--                  <p class="text-color">{{ info.trainCount }}</p>-->
<!--                  <p>总刷题量</p>-->
<!--                </div>-->
<!--                <div class="app-fgx"></div>-->
<!--                <div class="data-info-exam-data">-->
<!--                  <p class="text-color">{{ info.trainCount7days }}</p>-->
<!--                  <p>七日刷题数</p>-->
<!--                </div>-->
<!--                <div class="app-fgx"></div>-->
<!--                <div class="data-info-exam-data">-->
<!--                  <p class="text-color">{{ info.trainSiteRank }}</p>-->
<!--                  <p>组织排名</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="data-info-r-b app-bj-color">-->
<!--            <div class="data-info-r-b-num">-->
<!--              <p></p>-->
<!--              <p>激活题库次数</p>-->
<!--              <p>{{ info.cdkeyActiveCount }}</p>-->
<!--            </div>-->
<!--            <div class="data-info-r-b-num">-->
<!--              <p></p>-->
<!--              <p>邀请链接点击数</p>-->
<!--              <p>{{ info.invitationShowCount }}</p>-->
<!--            </div>-->
<!--            <div class="data-info-r-b-num">-->
<!--              <p></p>-->
<!--              <p>邀请注册数</p>-->
<!--              <p>{{ info.invitationSignupCount }}</p>-->
<!--            </div>-->
<!--            <div class="data-info-r-b-num">-->
<!--              <p></p>-->
<!--              <p>邀请注册转化率</p>-->
<!--              <p>{{ info.invitationRate }}</p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="cont mt10">-->
<!--        <p class="cont-tit">快捷入口</p>-->
<!--        <div class="quick-entrance">-->
<!--          <div class="quick-entrance-item" @click="goCode">-->
<!--            <div class="quick-entrance-item-l">-->
<!--              <img src="../../assets/images/new_icon/code.png" />-->
<!--              <p>激活码管理</p>-->
<!--            </div>-->
<!--            <img width="8" height="14" src="../../assets/images/new_icon/Ic_Arrow_R.png" />-->
<!--          </div>-->
<!--          <div class="quick-entrance-item" @click="goInvite">-->
<!--            <div class="quick-entrance-item-l">-->
<!--              <img src="../../assets/images/new_icon/invite_link.png" />-->
<!--              <p>邀请链接</p>-->
<!--            </div>-->
<!--            <img width="8" height="14" src="../../assets/images/new_icon/Ic_Arrow_R.png" />-->
<!--          </div>-->
<!--          <div class="quick-entrance-item" @click="goUser">-->
<!--            <div class="quick-entrance-item-l">-->
<!--              <img src="../../assets/images/new_icon/user.png" />-->
<!--              <p>创建用户</p>-->
<!--            </div>-->
<!--            <img width="8" height="14" src="../../assets/images/new_icon/Ic_Arrow_R.png" />-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="cont mt10">
        <StuTrainStat :showExport="true"></StuTrainStat>
      </div>
      <Row :gutter="10">
        <Col span="12">
          <div class="cont mt10">
            <div class="cont-tit">日活跃人数</div>
            <div id="user-active-chart" class="chart-content"></div>
          </div>
        </Col>
        <Col span="12">
          <div class="cont mt10">
            <div class="cont-tit">邀请注册人数统计</div>
            <div id="invitation-chart" class="chart-content"></div>
          </div>
        </Col>
      </Row>
      <Row :gutter="10">
        <Col span="12">
          <div class="cont mt10">
            <div class="active-num-top">
              <div class="tit">每日刷题量</div>
            </div>
            <div id="user-train-stat-chart" class="chart-content"></div>
          </div>
        </Col>
        <Col span="12">
          <div class="cont mt10">
            <div class="active-num-top">
              <div class="tit">每日刷题人数</div>
            </div>
            <div id="user-train-count-chart" class="chart-content"></div>
          </div>
        </Col>
      </Row>
<!--      <div class="cont mt10">-->
<!--        <div class="cont-tit">各分类刷题量</div>-->
<!--        <div id="category-stat-chart" class="chart-content"></div>-->
<!--      </div>-->
<!--      <div class="cont mt10">-->
<!--        <div class="cont-tit">分类每天刷题量</div>-->
<!--        <div class="search">-->
<!--          <div class="search-item">-->
<!--            <div class="name">-->
<!--              一级分类:-->
<!--            </div>-->
<!--            <div class="search-cont">-->
<!--              <div class="values">-->
<!--                <div :class="curCategoryId == item.id ? 'active' : ''" v-for="item in categoryList" :key="item.id + 'category'" @click="changeSearch(item,'curCategoryId')">{{item.name}}</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="search-item" v-if="secondCategoryList.length">-->
<!--            <div class="name">-->
<!--              二级分类:-->
<!--            </div>-->
<!--            <div class="search-cont">-->
<!--              <div class="values" >-->
<!--                <div :class="cursecondCategoryId == item.id ? 'active' : ''" v-for="item in secondCategoryList" :key="item.id + 'secondCategory'" @click="changeSearch(item,'cursecondCategoryId')">{{item.name}}</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div id="category-day-chart" class="chart-content" style="height: 400px"></div>-->
<!--      </div>-->
    </div>
    <div class="no-data" v-else>
      <Spin fix></Spin>
    </div>
  </div>
</template>

<script>
import StuTrainStat from '@/components/stuTrainStat'
var myCategoryDayChart;
export default {
  name: "teacherStat.vue",
  data(){
    return{
      info:{},
      curCategoryId:'',
      cursecondCategoryId:'',
      categoryList:[],
      secondCategoryList:[],
      statUserCategoryStat:[],
      pageStatus:true,
    }
  },
  components:{
    StuTrainStat
  },
  mounted(){
    this.getData();

  },
  beforeDestroy(){
    this.pageStatus = false;
    window.removeEventListener("resize",this.chartResize());
  },
  methods:{
    getData(){
      this.api.home.indexManager().then((res)=>{
        this.info = res;

        this.$nextTick(()=>{
          if(this.pageStatus){
            this.setInvitationOption();
            this.setUserActiveOption();
            this.setUserTrainCountOption();
            this.setUserTrainStatOption();


            // this.setCategoryStatOption();
            // this.getStatByCategoryTrainCount();
            this.getCategoryList();
          }

        })
      })
    },
    setInvitationOption(){
      let chartDom = document.getElementById('invitation-chart');
      let myChart = this.$echarts.init(chartDom);
      let option;

      let names = [];
      let counts = [];
      this.info.invitationUserList.forEach((res)=>{
        names.push(res.date);
        counts.push(res.count);
      });

      option = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '40px',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '注册人数',
            type: 'line',
            stack: 'Total',
            data: counts
          },
        ]
      };

      option && myChart.setOption(option);

      window.addEventListener('resize', this.chartResize(myChart))
    },
    setUserActiveOption(){
      let chartDom = document.getElementById('user-active-chart');
      let myChart = this.$echarts.init(chartDom);
      let option;

      let names = [];
      let counts = [];
      this.info.userActiveStat.forEach((res)=>{
        names.push(res.date);
        counts.push(res.count);
      });

      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          // data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
          right:0
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          //data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          // {
          //   name: 'Email',
          //   type: 'line',
          //   stack: 'Total',
          //   data: [120, 132, 101, 134, 90, 230, 210]
          // },
          {
            name: '活跃人数',
            type: 'line',
            stack: 'Total',
            data: counts
          },
        ]
      };

      option && myChart.setOption(option);

      window.addEventListener('resize', this.chartResize(myChart))
    },
    chartResize(myChart){
      if (myChart) {
        myChart.resize()
      }
    },
    setUserTrainCountOption(){
      let chartDom = document.getElementById('user-train-count-chart');
      let myChart = this.$echarts.init(chartDom);
      let option;

      let names = [];
      let counts = [];
      this.info.statUserCountDay.forEach((res)=>{
        names.push(res.date);
        counts.push(res.count);
      });

      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          // data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
          right:0
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          //data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          // {
          //   name: 'Email',
          //   type: 'line',
          //   stack: 'Total',
          //   data: [120, 132, 101, 134, 90, 230, 210]
          // },
          {
            name: '刷题人数',
            type: 'line',
            stack: 'Total',
            data: counts
          },
        ]
      };

      option && myChart.setOption(option);

      window.addEventListener('resize', this.chartResize(myChart))
    },

    setUserTrainStatOption(){
      let chartDom = document.getElementById('user-train-stat-chart');
      let myChart = this.$echarts.init(chartDom);
      let option;

      let names = [];
      let counts = [];
      this.info.statUserDayList.forEach((res)=>{
        names.push(res.date);
        counts.push(res.count);
      });

      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          // data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
          right:0
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          //data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          // {
          //   name: 'Email',
          //   type: 'line',
          //   stack: 'Total',
          //   data: [120, 132, 101, 134, 90, 230, 210]
          // },
          {
            name: '刷题量',
            type: 'line',
            stack: 'Total',
            data: counts
          },
        ]
      };

      option && myChart.setOption(option);

      window.addEventListener('resize', this.chartResize(myChart))
    },
    getCategoryList(){
      let data = {
        page:1,
        pageSize:10,
        site_id:this.$route.params.id,
      };

      this.api.dataset.categoryForm(data).then((res)=>{

        //一级标签
        this.categoryList = [
          ...res.categoryFirstList
        ];
        this.curCategoryId = this.categoryList[0].id;
        this.getSecondCategoryList();
      })
    },
    getSecondCategoryList(){
      let data = {
        page:1,
        pageSize:10,
        category_id:this.curCategoryId,
        site_id:this.$route.params.id,
      };

      this.api.dataset.categoryForm(data).then((res)=>{

        //二级标签
        this.secondCategoryList = [
          ...res.categorySecondList
        ];

        this.cursecondCategoryId = this.secondCategoryList[0].id;
        // this.trainCountStatByCategory();

      })
    },
    trainCountStatByCategory(){
      let params = {
        category_id:this.cursecondCategoryId,
        site_id:this.$route.params.id,
      }
      this.api.index.trainCountStatByCategory(params).then((res)=>{
        this.categoryDayStatList = res.statUserCategoryDayStat;
        let seriesData = [];
        let legendData = [];
        // this.categoryStatList = res.categoryStatList;
        if(res.statUserCategoryDayStat.length){
          let date = res.statUserCategoryDayStat[0].map((item)=>{
            return item.date;
          });
          res.statUserCategoryDayStat.forEach((item)=>{
            let data = [];
            item.forEach((sItem)=>{
              data.push(sItem.count)
            })
            seriesData.push({
              name: item[0].name,
              type: 'line',
              // stack: 'Total',
              data:data,
            });
            legendData.push(item[0].name)
          })
          // if(this.pageStatus){
          //   this.setCategoryDayChart(date,seriesData,legendData);
          //
          // }
        }
        // else{
        //   if(this.pageStatus){
        //     this.setCategoryDayChart([],[],[]);
        //   }
        // }
      })
    },
    getStatByCategoryTrainCount(){
      this.api.home.statByCategoryTrainCount().then((res)=>{
        if(this.pageStatus){
          this.setCategoryStatOption(res.statUserCategoryStat);
        }
      })

    },
    setCategoryDayChart(date,seriesData,legendData){
      var chartDom = document.getElementById('category-day-chart');
      if (myCategoryDayChart != null && myCategoryDayChart != "" && myCategoryDayChart != undefined) {

        myCategoryDayChart.dispose(); //销毁

      }
      myCategoryDayChart = this.$echarts.init(chartDom);
      var option;

      option = {
        tooltip:{
          trigger: 'axis',
          show:true
        },
        legend: {
          data: legendData
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          data: date
        },
        yAxis: {
          type: 'value'
        },
        series: seriesData
      };

      option && myCategoryDayChart.setOption(option,true);

      window.addEventListener('resize', this.chartResize(myCategoryDayChart))

    },
    changeSearch(data,name){
      this[name] = data.id;
      if(name == 'curCategoryId'){
        this.getSecondCategoryList();
      }
      if(name == 'cursecondCategoryId'){
        this.trainCountStatByCategory();
      }
    },
    setCategoryStatOption(data){
      let chartDom = document.getElementById('category-stat-chart');
      let myChart = this.$echarts.init(chartDom);
      let option;

      let names = [];
      let counts = [];
      data.forEach((res)=>{
        names.push(res.name);
        counts.push(res.count);
      });

      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          // data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
          //right:0
        },
        grid: {
          left: '40px',
          right: '40px',
          bottom: '0%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          //boundaryGap: false,
          //data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          // {
          //   name: 'Email',
          //   type: 'line',
          //   stack: 'Total',
          //   data: [120, 132, 101, 134, 90, 230, 210]
          // },
          {
            name: '刷题量',
            type: 'bar',
            stack: 'Total',
            data: counts,
            barMaxWidth:'60'
          },
        ]
      };

      option && myChart.setOption(option);

      window.addEventListener('resize', this.chartResize(myChart))
    },
    goCode(){
      this.$router.push({
        path:'/cdkey/list'
      })
    },
    goInvite(){
      this.$router.push({
        path:'/mysite/invitation'
      })
    },
    goUser(){
      this.$router.push({
        path:'/user/mylist'
      })
    },
  }
}
</script>

<style scoped lang="scss">
.home-teacher{
  margin: 20px;
  font-size: 14px;
  .cont{
    background: #FFFFFF;
    padding: 20px;
    .cont-tit{
      font-weight: bold;
      font-size: 16px;
    }
  }
  .tit{
    margin-bottom: 18px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
  }
  .quick-entrance{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .quick-entrance-item{
      padding: 0 20px 0 12px;
      margin-right: 25px;
      flex: 1;
      height: 50px;
      border: 1px solid #E7E7E7;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      >div{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >img{
          width: 34px;
          height: 34px;
        }
      }
    }
    .quick-entrance-item:last-child{
      margin-right: 0;
    }
  }
  .data-info{
    .data-info-r-t{
      display: flex;
      justify-content: flex-start;

      .data-info-exam{
        padding:15px 20px;
        flex: 1;
        border-radius: 6px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #666666;
        height: 92px;
        .data-info-exam-value{
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex: 1;
          height: 100%;
        }

        .data-info-exam-data{
          >p:first-child{
            font-size: 20px;
            font-weight: bold;
          }
        }
      }
    }
    .data-info-r-b{
      margin-top: 14px;
      padding: 25px 20px;
      display: flex;
      justify-content: flex-start;
      background: #ECF4FA;
      border-radius: 6px;
      color: #666666;

      .data-info-r-b-num{
        flex: 1;
        margin-left: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >p:first-child{
          margin-right: 6px;
          width: 8px;
          height: 8px;
          background: #333333;
        }
        >p:last-child{
          margin-left: 7px;
          font-size: 20px;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
  .search{
    margin-top: 20px;
    margin-bottom: 20px;
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }
      .search-cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            border-radius: 4px;
          }
        }
        .more{
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;
          .more-icon{
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }
  .ml20{
    margin-left: 20px;
  }
  .mb20{
    margin-bottom: 20px;
  }
  .chart-content{
    width: 100%;
    height: 400px;
  }
  .no-data{
    position: relative;
    width: 100%;
    height: 800px;
  }
}

</style>
