<template>
<div class="stu-train-stat">
  <div class="stu-train-stat-tit">
    <p>学员刷题排行</p>
    <Button type="primary" @click="exportRank" v-if="showExport">导出</Button>
  </div>
  <div class="stu-train-stat-search">
    <div class="stu-train-stat-search-item">
      <p>刷题时间</p>
      <DatePicker type="daterange" placeholder="请选择时间" format="yyyy/MM/dd" :options="dateOption" style="width: 200px" :value="dataArr" @on-change="changeDate"></DatePicker>
    </div>
    <div class="stu-train-stat-search-item" v-if="!userGroupId">
      <p>班级</p>
      <Select v-model="groupId" style="width:200px" clearable @on-change="changeGroup">
        <Option v-for="item in groupList" :value="item.id" :key="item.id">{{ item.name }}</Option>
      </Select>
    </div>
  </div>
  <div class="table">
    <Table border :columns="columns" :loading="loading" :data="data"></Table>
  </div>
  <div class="page">
    <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
  </div>
</div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "stuTrainStat.vue",
  data(){
    return {
      groupId:'',
      groupList:[],
      page:1,
      pageSize:10,
      total:0,
      loading:false,
      columns:[
        {
          title: 'ID',
          key: 'user_id',
        },
        {
          title: '姓名',
          key: 'id',
          render: (h, para) => {
            return h('div', {
            }, para.row.user.nickname);
          },
        },
        {
          title: '分类题库',
          key: 'category_name',
        },
        {
          title: '刷题量',
          key: 'count',
        },
        {
          title:"浏览量",
          key:"view_count"
        },
        {
          title: '正确率',
          key: 'right_rate',
        },
      ],
      data:[],
      dateStart:'',
      dateEnd:'',
      dateOption:{
        disabledDate(date) {
          return date && date.valueOf() > Date.now();
        },
        shortcuts: [
          {
            text: '昨天',
            value () {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              return [start, end];
            }
          },
          {
            text: '近3天',
            value () {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
              return [start, end];
            }
          },
          {
            text: '近1周',
            value () {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            }
          },
        ]
      },
      dataArr:[],
    }
  },
  props:{
    userId:{
      type:String,
      default:'',
    },
    siteId:{
      type:String,
      default:'',
    },
    userGroupId:{
      type:String,
      default:'',
    },
    showExport:{
      type:Boolean,
      default:false,
    }
  },
  created(){
    if(!this.userGroupId){
      this.getGroupList();
    }

    this.getList();
  },
  methods:{
    getGroupList(){
      let params = {
        pageSize:99
      };
      if(this.userId){
        params.user_id = this.userId;
      }
      if(this.siteId){
        params.site_id = this.siteId;
      }
      this.api.user.groupList(params).then((res)=>{
        this.groupList = res.list;
      })
    },
    getList(){
      let params = {
        date_start:new Date(this.dateStart).getTime()/1000,
        date_end:new Date(this.dateEnd).getTime()/1000,
        page:this.page,
        pageSize:this.pageSize
      }
      if(this.userGroupId){
        params.group_id = this.userGroupId;
      }else{
        params.group_id = this.groupId;
      }
      if(this.userId){
        params.user_id = this.userId;
      }
      if(this.siteId){
        params.site_id = this.siteId;
      }
      this.loading = true;
      this.api.dataset.datasetTrainStatUsers(params).then((res)=>{
        this.loading = false;
        this.data = res.list;
        this.total = Number(res.count);

        this.dataArr = [util.timeFormatter(
            new Date(+res.date_start * 1000),
            'yyyy-MM-dd'
        ),util.timeFormatter(
            new Date(+res.date_end * 1000),
            'yyyy-MM-dd'
        )]
      }).catch(()=>{
        this.loading = false;
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    changeDate(data){
      this.dateStart = data[0];
      this.dateEnd = data[1];
      this.page = 1;
      this.getList();
    },
    changeGroup(){
      this.page = 1;
      this.getList();
    },
    exportRank(){
      let params = {
        date_start:new Date(this.dateStart).getTime()/1000,
        date_end:new Date(this.dateEnd).getTime()/1000,
        // category_id:
      }
      if(this.userGroupId){
        params.group_id = this.userGroupId;
      }else{
        params.group_id = this.groupId;
      }
      if(this.userId){
        params.user_id = this.userId;
      }
      if(this.siteId){
        params.site_id = this.siteId;
      }
      this.api.dataset.datasetTrainStatUsersExport(params).then((res)=>{
        let uploadParams = {
          file:res.filepath
        };
        this.api.codekey.uploadfileDownload(uploadParams).then((res)=>{
          util.downLoad(res)
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.stu-train-stat{
  //margin-top: 20px;
  //padding: 20px;
  //border-radius: 4px;
  //box-shadow: 0px 2px 12px 0px rgb(189 189 189 / 41%);
  //border: 1px solid #dcdee2;

  .stu-train-stat-tit{
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .stu-train-stat-search{
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .stu-train-stat-search-item{
      margin-right: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >p{
        margin-right: 10px;
      }
    }
  }
  .table{
    margin-top: 20px;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
</style>
